

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();


$dark-primary-text: rgba(black, 0.87);
$light-primary-text: white;
$zm-primary-color : #25397E;
$zm-primary-var1-color : #0252cd;
$zm-primary-var2-color : #3D81B5;
$zm-lines-color: #6F6F6F;
$zm-accent-color: #55C9EB;



$zm-palette-map-default: (
  50: #e3f1fb,
  100: #bcdbf5,
  200: #93c5ef,
  300: #6daee7,
  400: #529ee3,
  500: $zm-primary-var1-color,
  600: #3881d1,
  700: #316fbe,
  800: #2b5fac,
  900: #21428c,

  A100: #82b1ff,
  A200: #448aff,
  A400: #2979ff,
  A700: #2962ff,
  contrast: (50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);

$zm-palette-map-accent: (
  50: #e1f6fb,
  100: #b4e7f5,
  200: #83d8f0,
  300: #55c8eb,
  400: #30bce9,
  500: $zm-primary-var2-color,
  600: #00a2db,
  700: #008fc8,
  800: #007eb5,
  900: #005f96,

  A100: #82b1ff,
  A200: #448aff,
  A400: #2979ff,
  A700: #2962ff,
  contrast: (50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);



// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$trianz-primary: mat.define-palette($zm-palette-map-default);
$trianz-accent: mat.define-palette($zm-palette-map-accent, A200, A100, A400);

// The warn palette is optional (defaults to red).
$trianz-warn: mat.define-palette(mat.$red-palette);

$trianz-typography: mat.define-typography-config(
    $font-family: 'Greycliffcf'
  );

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$trianz-theme: mat.define-light-theme((color: (primary: $trianz-primary,
        accent: $trianz-accent,
        warn: $trianz-warn,
      ),
      typography: $trianz-typography,
      density: -1,
      ));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($trianz-theme);

@include mat.form-field-density(-3);

// reduce the vertical height of checkboxes
.mat-mdc-checkbox{
  height: 30px;
}


// @media (-webkit-device-pixel-ratio: 1.25) {
//   * {
//     zoom: 0.99;
//   }
// }
// @media (-webkit-device-pixel-ratio: 1.5) {
//   * {
//     zoom: 0.985;
//   }
// }


// /* You can add global styles to this file, and also import other style files */

* {
  box-sizing: border-box;

}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Greycliffcf' !important;
  font-display: swap;
}
/*new css*/
.mat-mdc-card-header-text{
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.mat-mdc-card-header .mat-mdc-card-header-text .mat-mdc-card-title {
  font-weight: 700;
  color: #000;
  font-size: 20px;
}
.accordion-button {
  color: #000 !important;
  font-size: 18px;
  font-weight: 600;
}
.accordion-button:not(.collapsed) {
  color: #000 !important;
  font-size: 18px;
  font-weight: 600;
}
/*new css*/
//Facet-filter
.accordion-button:not(.collapsed){
  background-color: #fff !important;
}
.accordion-button:not(.collapsed) {
  color: #212529 !important;
}
h3.accordion-header{
  padding-bottom: 0px;
}
.accordion-body span.d-flex.mt-3.justify-content-end {
  justify-content: left !important;
  margin-top: 10px !important;
}
.filter-chips.d-flex {
  margin-bottom: 15px;
}
.filter-chips .label.me-2,
.cdk-overlay-pane h5.ps-3.pt-3 {
  font-size: 16px !important;
  font-weight: 600 !important;
}
.filter-chips button.mx-2 {
  background: #0252cd;
  color: #fff;
  border-radius: 4px;
  border: 0;
  font-size: 15px;
  height: 38px;
}
.collapsed-chips {
  max-width: 89% !important;
}
.mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #0252cd !important;
}
.cdk-overlay-container .d-flex.justify-content-between.mb-3.mt-2.mx-1 {
  margin: 10px 20px !important;
}
//Facet-filter
.zm-loader-overlay {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(248 249 250 / 0.4);
  justify-content: center;
  align-items: center;
  z-index: 998;
}

.zm-loader {
  width: 48px;
  height: 48px;
  border: 3px dotted #FFF;
  border-style: solid solid dotted dotted;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  box-sizing: border-box;
  animation: rotation 2s linear infinite;
}

.zm-loader::after {
  content: '';  
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 3px dotted #0252cd;
  border-style: solid solid dotted;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  animation: rotationBack 1s linear infinite;
  transform-origin: center center;
}
.zm-loader::before {
  width: 32px;
  height: 32px;
  border-color: #0252cd #0252cd transparent transparent;
  animation: rotation 1.5s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 
@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
} 

.job-list-sec .container{
  width: auto !important;
}

.mat-mdc-card-content .flex-nowrap .col{
  width: auto;
  display: inline;
  flex: inherit;
  padding: 0;
}
.mat-mdc-card-content .flex-nowrap .col.fw-bold{
  font-weight: 700 !important;
  margin-left: 5px;
  overflow-wrap: break-word;
  white-space: nowrap;
  overflow: hidden;
  width: 66%;
  display: block;
  text-overflow: ellipsis;
  
}
.mat-mdc-card-content .row{
  margin-left: -7px;
  margin-right: -7px;
}
.deparment-list .mat-mdc-card-content .row{
  margin-left: 0px;
  margin-right: 0px;
}
.mat-mdc-card-header-text{
  overflow: hidden;
}
.mat-mdc-card-header-text .mat-mdc-card-title a{
  overflow-wrap: break-word;
  white-space: nowrap;
  overflow: hidden;
  width: 90%;
  display: block;
  text-overflow: ellipsis;

}
.mat-mdc-card-title a{
  font-size: 16px;
}
.mdc-card__actions{
  justify-content: left !important;
}
.mat-mdc-card{
  margin-bottom: 20px;
  padding: 14px 0;
}
.search-bar .search-bar-input{
  width: 75% !important;
}
.search-bar .action-btns-group{
  width: 25% !important;
}
.search-bar .action-btns-group .mat-mdc-unelevated-button, .search-bar .action-btns-group .mat-mdc-outlined-button{
  height: 45px;
  top: -5px;
}
.section__xp__cta .exp-submit-mobile{
  margin-top: 15px;
  padding: 6px 14px;
  height: auto;
}
.section-label label{
  font-size: 14px;
  font-weight: 600;
  color: #424242!important;
  padding: 5px 0px;
  text-align: left;
  white-space: initial;
}
.facet-lib .header{
  margin-bottom: 20px;
  align-items: end;
}
.mat-mdc-dialog-container .mdc-dialog__title{
  font-size: 16px !important;
}
.mdc-form-field>label{
  font-weight: normal !important;
}
// Mid-Day css
.header__right .mat-mdc-outlined-button:not(:disabled){
  background-color: rgba(255, 0, 0, 0);
  color: #0252cd !important;
  font-weight: 700;
  text-decoration-line: underline;
  border: none;

}
.mat-mdc-outlined-button:not(:disabled) {
  border-color: var(--mdc-outlined-button-outline-color, rgba(0, 0, 0, 0.12));
  background: #0252cd;
  color: rgb(255, 255, 255) !important;
  border-radius: 5px;
}
// .job-list-sec .container.text-center .row.gy-3 .col-md-4.col-sm-12{
//   width: 100%;
// }
.mat-mdc-card {
  transition: box-shadow .15s ease-in-out;
  border: 0;
  box-shadow: 0 0 2px 0 rgb(0 0 0 / 16%);
  padding: 14px;
}
mat-card[_ngcontent-ghq-c272]:hover{
  box-shadow: none !important;
}
.mat-mdc-card-title a{
  color: #000 !important;
  font-weight: 600;
  font-size: 22px;
}
.mat-mdc-card-content, .flex-nowrap .col.fw-bold
{
  font-weight: 400!important;
  font-size: 14px!important;
  color: #000;
}
@media (min-width: 992px) {
//   .mdc-card__actions {
//     position: absolute;
//     right: 0;
//     top: 23px;
// }
.job-apply.container .zm-margin-top-5 .col-md-7.file-input{
  width: 33.32%;
}
.job-apply.container .zm-margin-top-5 .col-md-9 {
    width: 50%;
}
//Facet-filter
.cdk-overlay-pane {
  width: unset !important;
}
.filter-chips .label.me-2 {
  display: none;
}
//Facet-filter
}

.mat-mdc-card-actions.mdc-card__actions .mdc-button.mat-mdc-button.mat-primary.mat-mdc-button-base
{
  width: auto;
  height: auto;
  display: block;
  background-color: rgba(2, 82, 205, 0) !important;
  border-radius: 5px !important;
  color: #0252cd !important;
  padding: 6px 14px;
  /* border-radius: 5px; */
  border: none;
  font-weight: 500;
  font-size: 14px;
  padding: 0;
  text-align: left;
}
lib-job .mdc-card__actions {
  padding: 0;
}

// job-view-page

.job-view.container .row .col-md-3.attribute-label, .job-view.container .row .col-md-9.attribute-data {
  width: 100% !important;
}
// job-view-page
.job-view__attribute label.attribute-text, .job-view label.attribute-text {
  color: #000 !important;
  font-weight: 600 !important;
  font-size: 22px;
}
.job-view__attribute span.colon {
  display: none;
}
p {
  color: #000000;
  font-weight: 400;
  font-size: 16px;
}

.mat-mdc-text-field-wrapper {
  background-color: #eeeeee;
  border-radius: 5px !important;
  font-size: 16px;
}
.col-md-2.file-btn .mat-mdc-unelevated-button:not(:disabled) {
  border-radius: 0px 5px 5px 0px;
  background-color: #0252cd !important;
  border: 1.5px solid;
  color: #fff !important;
  border-color: #0252cd !important;

}
.mat-mdc-text-field-wrapper {
  width: 100%;
  border-radius: 5px 0px 0px 5px !important;
}
.footer.text-center .mat-mdc-raised-button:not(:disabled) {
  background: #0252cd!important;
  color: #333!important;
  height: 45px;
  padding: 6px 28px;
}
.job-view__title h2
{
  font-size: 20px;
    font-weight: 600;
    color: #424242!important;
}
.job-apply.container label {
  display: inline-block;
  margin-bottom: 5px;
  font-weight: 700;
  font-weight: normal !important;
  color: #424242 !important;
  font-size: 15px !important;
}
.section[_ngcontent-onn-c281] {
  padding: 2em 0px !important;
}
.joblist-sec .search-bar .action-btns-group .mat-mdc-unelevated-button {
  display: none;
}
.mat-mdc-checkbox label {
  cursor: pointer;
  font-weight: 400!important;
  font-size: 13px!important;
  color: #424242;
}

.mat-mdc-form-field-hint-wrapper .mat-mdc-form-field-hint-spacer {
  flex: unset;
}
.mat-mdc-form-field-subscript-wrapper.mat-mdc-form-field-bottom-align .mat-mdc-form-field-hint-wrapper
{
  padding: 0 5px;
}
.label.section-label .mat-divider {
  display: none;
}
.section__xp__fields .mat-mdc-text-field-wrapper {
  background: #fff;
}

@media (max-width: 495px){

.width-auto.facet-lib .header, .width-auto.facet-lib .section{
  padding: 0px 20px !important;
}
lib-zm-search .search-bar{
  padding: 20px 20px 20px 20px !important;
}
}
@media (min-width: 1796px){
.container {
    width: 1753px;
}
}

@media (min-width: 320px) and (max-width: 500px){
  .search-bar {
      flex-direction: unset !important;
  }}
  .mat-mdc-checkbox label {
    text-transform: capitalize;
}
@media (max-width: 991px){
h2.abt-cntr, .value-cntr.white-color {
    font-size: 20px!important;
}

.pd-tp-10{
  padding-top:10px
}
p {
  font-size: 14px;
}
lib-zm-search .search-bar {
  padding: 22px 0px 0px 0px !important;
}
.width-auto.facet-lib .header, .width-auto.facet-lib .section {
  padding: 0px 0px !important;
}
.job-apply.container .zm-margin-top-5 .col-md-7.file-input{
  padding: 0!important;
}
.job-apply.container .zm-margin-top-5 .col-md-2.file-btn button{
  width: 100%!important;
  border-radius: 5px !important;
}
.job-apply.container .zm-margin-top-5 .col-md-9{
  padding-right: 0px;
  padding-left: 0px
}
.job-apply.container .zm-margin-top-5 .col-md-3.attribute-label{
  display: block;
  padding-right: 0px;
  padding-left: 0px;
}
.job-list-sec .mdc-card__actions{
  justify-content: left !important;
  padding-top: 15px;
  padding-left: 0;

}
.mat-mdc-card-actions.mdc-card__actions .mdc-button.mat-mdc-button.mat-primary.mat-mdc-button-base{
  width: 25%;
  height: 35px;
}
}
.job-apply.container .footer.text-center button{
  color: #fff !important;
  height: 43px;
  font-weight: 500;
  font-size: 14px;
  padding: 6px 48px;
  border: none;
}
.mat-mdc-checkbox label {

  cursor: pointer;

  padding: calc((var(--mdc-checkbox-state-layer-size, 40px) - 18px) / 2);

}
// Mid-Day css

.brdr-btm-nn {
  border-bottom: none !important;
}
h5.vlue-heading {
  font-size: 22px;
  line-height: 24px;
}
// @media (min-width: 1200px){
// .container {
//     margin: 0 auto;
//     padding: 0 min(4.166vw, 80px);
//     width: 100%;
//     max-width: 1920px;
// }
// }
lib-zm-search .mat-mdc-outlined-button:not(:disabled) {
  // border-color: #0252cd;
  color: #fff !important;
  // background-color: #fff !important;
}
lib-zm-search .mat-icon {
  color: #0252cd !important;
}
@media (min-width: 992px){
.mtb-30.mt-80.joblist-sec.pd-btm-50 .container .row .col-md-9{
  padding-left: 40px;
}
// lib-facets.width-auto.facet-lib div  {
//   padding: 0px 35px 0px 0px;
// }
lib-facets.width-auto.facet-lib div .mat-divider.mat-divider-vertical {
  padding: 0px 35px 0px 0px;
  left: 24%;
  right: inherit;
}
// .mrgn-tp-81{
//   margin-top: 81px;
// }
.job-list-sec .container .row .col-md-4 {
  margin-top: 8px;
  padding-left: 0;
}
}

  .mat-mdc-card-header {
    padding: 16px 0px 0 !important;
}
.mat-mdc-card-content {
  padding: 0 0px !important;
}
// .job-list-sec .container.text-center .row.gy-3 .col-md-4.col-sm-12 {
//   padding-right: 0;
//   padding-left: 0;
// }
a:focus, a:hover {
  text-decoration: none;
}

lib-jobs-list .mat-mdc-unelevated-button:not(:disabled) {
  border-radius: 50px;
  border-radius: 5px;
  padding: 10px 25px;
  height: 45px;
  margin-top: 40px;
}
.width-auto.facet-lib .header .header__left{
  color: #000;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 15px;
  height: 27px;
}
@media(max-width:768px)
{
  // .mrgn-tp-81{
  //   margin-top: 51px;
  // }
  .navbar-toggle .icon-bar{
    background-color: #0252cd;
  }
  //Facet-filter
  .filter-chips.d-flex {
    margin-left: 20px;
    margin-right: 10px;
  }
  .filter-chips .label-mobile-view {
    max-width: 60% !important;
  }
  .filter-chips.d-flex {
    display: block !important;
  }
  mat-dialog-container .justify-content-end.footer {
    position: sticky;
    bottom: 0px;
    padding-top: 20px;
  }
  mat-dialog-container .justify-content-end.footer {
    position: fixed;
    bottom: 0px;
    padding-top: 10px;
    padding-bottom: 10px !important;
    right: 0;
    padding-bottom: 15px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
    border: 1px solid #E0E0E0;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    opacity: 1;
    width: 100%;
    z-index: 99;
  }
  .d-none-mob {
    display: none;
  }
  //Facet-filter
}
.mat-mdc-button.mat-primary:active, .mat-mdc-outlined-button.mat-primary:active, .mat-mdc-button.mat-primary:focus, .mat-mdc-outlined-button.mat-primary:focus {
  border: 2px solid #000;    background-color: #a8a8a8 !important;

}

.zm-margin-top-5 .row.resume-upload .col-md-10.resume-upload__ipt{
  padding-right: 0;
  float: left;
  width: 80%;
}
.row.resume-upload .row.err-mssg-align{
  margin-top: 4.5rem;

}
.col-md-10.resume-upload__ipt .mat-mdc-form-field-hint.mat-mdc-form-field-bottom-align.ng-tns-c70-9{
  padding-bottom: 20px;
}
.row.resume-upload .row.err-mssg-align .mat-mdc-form-field-error{
  position: relative;
  top: 35px;
  left: 23px;
}
.zm-margin-top-5 .row.resume-upload .col-md-2.resume-upload--btn{
  margin-left: 5px;
}
@media (max-width:991px) {
  .zm-margin-top-5 .row.resume-upload .col-md-2.resume-upload--btn{
    float: left;
    width: 16%;
    margin-left: 5px;
  }
  .mdc-card__actions{
    justify-content: right;
  }
  .row.resume-upload .row.err-mssg-align .mat-mdc-form-field-error{
    display: inline-block;
    top: 50px;
  }
  .zm-margin-top-5 .row.resume-upload .col-md-10.resume-upload__ipt input#mat-input-8 {
    font-size: 12px;
  }
  .col-md-2.resume-upload--btn .mdc-fab {
    width: 45px;
    height: 45px;
  }
}
.navbar-header a.navbar-brand {
  width: 100%;
}
.row.resume-upload .row.err-mssg-align {
  margin-top: 0;
  padding-left: 0;
}
@media (max-width:991px) {
.navbar-header a.navbar-brand {
  width: 65%;
}
.zm-margin-top-5 .row.resume-upload .col-md-2.resume-upload--btn {
  float: left;
  width: 16%;
  margin-left: 5px;
}
}

//new
.thm-clr lib-upload-resume .zm-custom-theme button
{
  color: #0252cd !important;
  background: transparent;
}
.section__xp__cta.d-flex.justify-content-center.mt-3{
  justify-content: start !important;
}
.thm-clr {
  color: #0252cd !important;
  text-decoration: none;
}
.font-weight-600 {
  font-weight: 600;
}
.cusr-pntr{
  cursor: pointer;
}
lib-find-your-spot h1{
  text-align: center;
  font-weight: 500;
  margin-bottom: 0px;
  padding-bottom: 20px;
  font-size: 25px !important;
  color: #0252cd !important;
  line-height: 1.2;
  text-transform: uppercase;
}
lib-find-your-spot p{
  font-size: 16px !important;
  color: #000 !important;
  padding-bottom: 20px;
  line-height: normal !important;
  text-align: center;
  font-style: normal;
  margin: 0 0 10px;
}
lib-find-your-spot .row.align-items-center.justify-content-center {
  display: flex;
  align-items: flex-start !important;
  width: 75%;
  margin: 0 auto;
}
lib-find-your-spot .row.align-items-center.justify-content-center .col-md-6, lib-find-your-spot .row.align-items-center.justify-content-center .col-md-2 {
  padding-right: 0;
  padding-left: 0;
}
lib-find-your-spot .row.align-items-center.justify-content-center .col-md-6, lib-find-your-spot .row.align-items-center.justify-content-center .col-md-2 {
  padding-right: 0;
  padding-left: 0;
}
lib-find-your-spot .row.align-items-center .col-md-2 .mdc-button{
  font-weight: 500!important;
  background-color: #fff;
  color: #0252cd!important;
  border: 1.5px solid #0252cd;
  font-size: 14px;
  line-height: 32px;
  margin-left: 10px;
  padding: 6px 12px;
  width: 100%;
  height: 45px;
  border-radius: 5px;
}
@media (max-width: 991px){
lib-find-your-spot .row.align-items-center .col-md-2 .mdc-button {
    margin-left: 0;
    margin-top: 40px;
}
.jb-rec-jbview lib-job-recommendation .job-recommendation.container {
  width: 100%;
}
}
section.section.section-job-match lib-find-your-spot .zm-custom-theme 
{    padding-bottom: 50px;
    padding-top: 50px;
}

.job-apply.container .footer.text-center button{
  // background: #0252cd !important;
    border-radius: 5px;
    font-weight: 500;
    font-size: 18px;
    height: 45px;
    padding: 6px 28px;
    margin-left: 7px;
    color: #fff;
    margin-right: 36%;
}
.col-md-4.jb-rec-jbview.pd-22rem.ng-star-inserted {
  padding: 15px;
  padding-top: 3rem;
  margin-top: 10px;
}
.jb-rec-jbview lib-job-recommendation .job-recommendation.container {
  width: 100%;
}
@media (min-width: 1153px){
.apply-form lib-job-apply .job-apply.container {
  width: 1153px;
}
}


@font-face {
  font-family: 'Greycliffcf';
  src: url('assets/fonts/GreycliffCF-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
  font-display: swap
}

@font-face {
  font-family: 'Greycliffcf';
  src: url('assets/fonts/GreycliffCF-ExtraBold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
  font-display: swap
}

@font-face {
  font-family: 'Greycliffcf';
  src: url('assets/fonts/GreycliffCF-DemiBold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
  font-display: swap
}

@font-face {
  font-family: 'Greycliffcf';
  src: url('assets/fonts/GreycliffCF-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap
}

.pstn-1 {
  position: relative;
  height: 400px;
  width: 90%;
}
.btm-lft {
  position: absolute;
  bottom: 0;
  left: 0;
}
.mn-hdng-1 {
  color: #fff !important;
  font-size: 32px !important;
  font-weight: 500 !important;
  text-align: left !important;
  line-height: 1.2;
  margin-bottom: 0.5rem;
  margin-top: 0;
  padding-bottom: 30px;
}
.txt-aln-cntr {
  text-align: center !important;
}
h3 {
  font-weight: 500;
  font-size: 37px;
  padding-bottom: 30px;
  color: #424242;
  line-height: 1.2;
}
.deparment-list .container .row.gy-3 .col-md-4.col-sm-6{
  width: 100%;
}
lib-job-es mat-card mat-card-content .col-12{
  padding-left: 0;
}

.nav-wrapper {
  background-color: rgb(238, 238, 238) !important;
}

@media (max-width: 767px) {

  .job-apply .section, .job-details .job-view .section{
    padding: 0em !important;
  }
  .job-apply.container .footer.text-center button{
    margin-right: unset;
  }
}

#return-to-top {
  position: fixed;
     left: 15px;
     bottom: 15px;
     width: 50px;
     height: 50px;
     display: block;
     text-decoration: none;
     -webkit-border-radius: 2px;
     -moz-border-radius: 2px;
     border-radius: 2px;
     font-weight: 700;
     display: none;
     -webkit-transition: all 0.3s linear;
     -moz-transition: all 0.3s ease;
     -ms-transition: all 0.3s ease;
     -o-transition: all 0.3s ease;
     transition: all 0.3s ease;
     background-color: #0252cd;
     color: #fff;
     z-index: 99;
}

#return-to-top i {
  margin: 0;
  position: relative;
  left: 13px;
  top: 5px;
  font-size: 35px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
@media (min-width: 991px) and (max-width: 1310px){
  .job-apply.container .zm-margin-top-5 .col-md-9 {
    width: 40%;
}
.job-apply.container .zm-margin-top-5 .col-md-7.file-input {
  width: 23.32%;
}
}
.cdk-focused.cdk-keyboard-focused, .btn:focus, button:focus, input:focus .main-footer-list ul li a:focus {
  outline: 2px solid #000;
}
lib-find-your-spot .text-danger.fw-bold
{
  text-align: center;
}
lib-job mat-card .mat-mdc-card-header,lib-job-recommendation .mat-mdc-card-header 
  {
  display: block;
}
lib-job-recommendation .mat-mdc-card-header-text .mat-mdc-card-title a {
  width: 95%;
}
.job-view-skillset .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled)
{
  background-color: #2b2b2b !important;
  padding: 2px 2px;
  margin-bottom: 6px;
  border-radius: 5px;
  height: 22px;
}
.job-view-skillset .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) .mdc-evolution-chip__text-label
{
  color: #fff!important;
  font-size: 12px;
}
// .job-view-skillset .mdc-evolution-chip-set .mdc-evolution-chip-set__chips {
//   height: 56px;
// }
.mat-mdc-standard-chip .mdc-evolution-chip__cell--primary, .mat-mdc-standard-chip .mdc-evolution-chip__action--primary, .mat-mdc-standard-chip .mat-mdc-chip-action-label {
  overflow: hidden !important;
}
.mdc-button{
  font-weight: 600;
}
.job-view p{
  font-size: 20px;
  color: #555;
}
lib-job-recommendation .mat-mdc-card-content .flex-nowrap {
  display: flex;
}